import {I18n} from 'common/translator/i18n';

import Bugsnag from '../../common/bugsnag';
import {
  InstallmentsPrequalPageType,
  SET_MODAL_HEIGHT_EVENT,
  SetModalHeightEventParams,
} from '../../types/paymentTerms';
import MessageListener from '../../common/MessageListener';
import {PayMessageSender} from '../../common/MessageSender';
import ShopLoginButton from '../loginButton/shop-login-button';
import {
  IFrameEventSource,
  MessageEventSource,
} from '../../common/MessageEventSource';
import {PAY_AUTH_DOMAIN, PAY_AUTH_DOMAIN_ALT} from '../../common/utils/urls';
import {getAnalyticsTraceId} from '../../common/utils';
import {
  constructLink,
  getVariantsFromAttributeString,
} from '../payButton/utils';
import {ATTRIBUTE_ANALYTICS_TRACE_ID} from '../../constants/loginButton';
import {DefaultComponentAnalyticsContext} from '../../constants/loginDefault';
import {Cart, PaymentOption} from '../payButton/types';
import {
  ShopActionType,
  AvailableLoanType,
  InstallmentPlan,
  ModalType,
  ModalUserAction,
  VariantModalDetails,
  SamplePlan,
  PrequalMessageEventData,
} from '../../types';
import {
  createStatusIndicator,
  ShopStatusIndicator,
  StatusIndicatorLoader,
} from '../../common/shop-status-indicator';

import {buildPrequalResultUrl} from './components/prequalOverlay/utilities';
import {InstallmentsPrequalOverlayModal} from './components/prequalOverlay';
import {
  getFormattedSamplePlans,
  getDynamicPDPTemplateContents,
  getNavigationButtons,
  convertPriceToNumber,
  getContinueToCheckoutButtonHtml,
  getDynamicPDPText,
  InstallmentsModal,
} from './utils';
import {MonorailTrackerPaymentTerms} from './components/MonorailTrackerPaymentTerms';

export class ShopifyInstallmentsPrequalModal
  extends HTMLElement
  implements InstallmentsModal
{
  private _contentModalWrapper!: HTMLDivElement;
  private _contentProcessingWrapper!: HTMLDivElement;
  private _contentStatusIndicator?: ShopStatusIndicator;
  private _featureIframeWrapper!: HTMLDivElement;
  private _closeButtons?: NodeListOf<Element>;
  private _samplePlans: SamplePlan[] = [];
  private _modalToken: string;
  private _navigationButtons!: Element;
  private _continueToCheckoutContainer!: Element;
  private _permalink?: string;
  private _monorailTracker: MonorailTrackerPaymentTerms;
  private _analyticsTraceId: string;
  private _iframe: HTMLIFrameElement | undefined;
  private _iframeMessageListener?: MessageListener<any> | undefined;
  private _iframeMessenger?: PayMessageSender;
  private _authorizeModalOpened = false;
  private _nextState = InstallmentsPrequalPageType.AuthorizeLoaded;
  private _variantInfo?: VariantModalDetails;
  private _cart?: Cart;
  private _loanTypes: AvailableLoanType[];
  private _eligible: boolean;
  private _productAmount: number;
  private _sellerId: string | undefined;
  private _prequalSideEffectEventReceived: boolean | undefined;
  private _completedEventReceived: boolean | undefined;
  private _i18n: I18n | null = null;
  private _installmentsPlans: InstallmentPlan[];
  private _priceWithoutInterest: string;
  private _initialized = false;

  constructor(
    modalToken: string,
    monorailTracker: MonorailTrackerPaymentTerms,
    loanTypes: AvailableLoanType[],
    eligible: boolean,
    installmentPlans: InstallmentPlan[],
    priceWithoutInterest: string,
    sellerId: string | undefined,
    variantInfo?: VariantModalDetails,
    cart?: Cart,
    analyticsTraceId?: string,
  ) {
    super();
    this._modalToken = modalToken;
    this._monorailTracker = monorailTracker;
    this._analyticsTraceId =
      analyticsTraceId ||
      this.getAttribute(ATTRIBUTE_ANALYTICS_TRACE_ID) ||
      getAnalyticsTraceId();
    this._variantInfo = variantInfo;
    this._cart = cart;
    this._loanTypes = loanTypes;
    this._eligible = eligible;
    this._productAmount = convertPriceToNumber(priceWithoutInterest);
    this._sellerId = sellerId;
    this._installmentsPlans = installmentPlans;
    this._priceWithoutInterest = priceWithoutInterest;

    this.attachShadow({
      mode: 'open',
      delegatesFocus: true,
    });
  }

  get focusLockTarget() {
    return this.rootContainer.querySelector(
      '#shopify-payment-terms-modal',
    )! as HTMLElement;
  }

  get rootContainer() {
    return this.shadowRoot!;
  }

  destroyIframe() {
    this._iframeMessageListener?.destroy();
  }

  handleClose = () => {
    this._monorailTracker.trackModalAction(
      this._modalToken,
      ModalUserAction.Close,
      this._permalink,
    );
    const event = new Event('shopify_modal_close');
    this.dispatchEvent(event);

    const shopLoginButton = document.querySelector(
      '.prequal',
    ) as ShopLoginButton;
    if (shopLoginButton) {
      document.body.removeChild(shopLoginButton);
    }
    this._closePrequalBuyerFormOverlay();
  };

  handleEscKey = (evt: KeyboardEvent) => {
    if (
      (evt.key === 'Escape' || evt.key === 'Esc') &&
      this._authorizeModalOpened
    ) {
      this._closeAuthorizeModal();
    } else if (evt.key === 'Escape' || evt.key === 'Esc') {
      this.handleClose();
    }
  };

  async connectedCallback() {
    if (this._initialized) return;
    await this._initTranslations();
    await this._initContent();

    this.#openHiddenLoginIframe();
    window.addEventListener('keydown', this.handleEscKey);
    this.addOverlayEventListeners();
  }

  #changeHeight(height: string) {
    const setHeightEvent = new CustomEvent<SetModalHeightEventParams>(
      SET_MODAL_HEIGHT_EVENT,
      {
        detail: {height, lockScroll: height !== ''},
        bubbles: true,
      },
    );
    this.dispatchEvent(setHeightEvent);
  }

  addOverlayEventListeners() {
    window.addEventListener('overlayClose', this.handleOverlayClose);
    window.addEventListener(
      'buyerOnboardingSuccess',
      this.handleBuyerOnboardingSuccess,
    );
    window.addEventListener('closeOverlayAndModal', () => {
      this.handleOverlayClose();
      this.handleClose();
    });
    this._initialized = true;
  }

  disconnectedCallback() {
    this._closeAuthorizeModal();
    if (!this._closeButtons) return;

    this._closeButtons.forEach((btn) =>
      btn.removeEventListener('click', this.handleClose),
    );

    window.removeEventListener('keydown', this.handleEscKey);
    this.destroyIframe();
  }

  handleOverlayClose = () => {
    document.querySelector('installments-prequal-overlay-modal')?.remove();
  };

  handleBuyerOnboardingSuccess = () => {
    this.handleOverlayClose();
    this._openFeatureIframe();
  };

  handlePrequalFlowSideEffect = (evt: Event) => {
    this._prequalSideEffectEventReceived = true;
    const event = evt as CustomEvent;
    if (event.detail.shopPayInstallmentsOnboarded) {
      this._nextState = InstallmentsPrequalPageType.ResultsPageLoaded;
    } else {
      this._nextState = InstallmentsPrequalPageType.BuyerFormOverlayLoaded;
    }

    // Asyc postMessages may result in an edge case where the completed event was received BEFORE the
    // prequal_flow_side_effect event was received, which means handleLoginCompleted returned early instead of
    // proceeding to next steps. So, we should invoke handleLoginCompleted again to proceed to next steps.
    if (this._completedEventReceived) {
      this.removeCheckIfYouQualifyButtonLoading();
      this.handleLoginCompleted();
    }
  };

  handleLoginCompleted = () => {
    this._completedEventReceived = true;

    // Do not proceed to next steps if the prequal_flow_side_effect event has not been received yet,
    // because the next steps depend on the spiOnboarded value from that event.
    if (!this._prequalSideEffectEventReceived) {
      this.addCheckIfYouQualifyButtonLoading();
      return;
    }
    switch (this._nextState) {
      case InstallmentsPrequalPageType.ResultsPageLoaded:
        this._openFeatureIframe();
        break;
      case InstallmentsPrequalPageType.BuyerFormOverlayLoaded:
        this._openPrequalBuyerFormOverlay();
        break;
    }
  };

  addCheckIfYouQualifyButtonLoading = () => {
    const loaderType = StatusIndicatorLoader.Branded;
    this._contentStatusIndicator = createStatusIndicator(loaderType);
    this._contentStatusIndicator.classList.add('shop-status-indicator-loading');
    this._navigationButtons
      .querySelector('shop-pay-button')!
      .classList.add('prequal-hidden-state');
    this._navigationButtons.appendChild(this._contentStatusIndicator);
    this._contentStatusIndicator.connectedCallback();
    this._contentStatusIndicator.setStatus({
      status: 'loading',
      message: '',
    });
  };

  removeCheckIfYouQualifyButtonLoading = () => {
    if (this._contentStatusIndicator) {
      this._navigationButtons
        .querySelector('shop-pay-button')!
        .classList.remove('prequal-hidden-state');
      this._navigationButtons.removeChild(this._contentStatusIndicator);
    }
  };

  handlePostMessage(event: PrequalMessageEventData) {
    switch (event.type) {
      case 'prequal_ready':
        this._iframeMessenger?.postMessage({
          type: 'createprequal',
          amount: this._productAmount,
          currency: 'EUR',
          sellerId: Number(this._sellerId),
        });
        break;
      case 'prequal_success':
      case 'prequal_error':
        this._showFeatureIframe();
        break;
      case 'prequal_missing_information':
        this._showMainContent();
        this._openPrequalBuyerFormOverlay();
        break;
      case 'close':
        this.handleClose();
        break;
      case 'continue_to_checkout':
        this._continueToCheckout();
        break;
      case 'resize_iframe':
        this._iframe!.style.height =
          event.height < this.getContentModalHeight()
            ? `€{this.getContentModalHeight()}px`
            : `€{event.height}px`;
        this._iframe!.style.width = '100%';
        break;
    }
  }

  getModalSamplePlans(): SamplePlan[] {
    return this._samplePlans;
  }

  getPermalink(): string | undefined {
    return this._permalink;
  }

  getModalToken(): string {
    return this._modalToken;
  }

  // Ensure that the contents of the modal take up the full height
  // of the screen when the content height is less than the screen height
  // On desktop, the modal height is fixed at 717px
  // On mobile, the modal height is dynamic based on the content
  getContentModalHeight(): number {
    const modalHeight = 717;
    const padding = 75;
    return window.innerHeight - padding < modalHeight - padding
      ? window.innerHeight - padding
      : modalHeight - padding;
  }

  getModalType() {
    if (!this._loanTypes?.length || !this._eligible) {
      return ModalType.Ineligible;
    }

    const hasZeroInterestLoan = this._samplePlans?.some(
      (plan) => plan.apr === 0 && plan.loanType === AvailableLoanType.Interest,
    );

    if (hasZeroInterestLoan) {
      const onlyZeroInterestLoans = this._samplePlans?.every(
        (plan) => plan.apr === 0,
      );
      return onlyZeroInterestLoans
        ? ModalType.ZeroInterestOnly
        : ModalType.ZeroInterest;
    }

    // At this point, we know that no zero interest loans are present
    const isAdaptiveRange =
      this._samplePlans?.some(
        (plan) => plan.loanType === AvailableLoanType.SplitPay,
      ) &&
      this._samplePlans?.some(
        (plan) => plan.loanType === AvailableLoanType.Interest,
      );

    return isAdaptiveRange ? ModalType.Adaptive : ModalType.InterestOnly;
  }

  _openFeatureIframe() {
    if (this._iframe) {
      return;
    }

    this._contentStatusIndicator = createStatusIndicator(
      StatusIndicatorLoader.Large,
    );
    const container = this._contentProcessingWrapper.querySelector(
      '.shop-modal-content-processing-loading-container',
    );
    container?.insertBefore(this._contentStatusIndicator, container.firstChild);
    this._contentStatusIndicator.setStatus({
      status: 'loading',
      message: '',
    });

    const url = buildPrequalResultUrl(this._analyticsTraceId);
    this._iframe = document.createElement('iframe');
    this._iframe.style.border = 'none';
    this._iframe.src = url;
    this._featureIframeWrapper.appendChild(this._iframe);
    this._showLoadingState();
    if (!this._iframeMessageListener) {
      this._iframeMessageListener = this.createListener(
        new IFrameEventSource(this._iframe),
      );
    }
    if (!this._iframeMessenger) {
      this._iframeMessenger = new PayMessageSender(this._iframe);
    }
  }

  createListener(eventSource: MessageEventSource) {
    const eventDestination: Window | undefined =
      this.ownerDocument?.defaultView || undefined;
    return new MessageListener<PrequalMessageEventData>(
      eventSource,
      [PAY_AUTH_DOMAIN, PAY_AUTH_DOMAIN_ALT, window.location.origin],
      this.handlePostMessage.bind(this),
      eventDestination,
    );
  }

  _getSellerIdInNumber() {
    return this._sellerId ? Number.parseInt(this._sellerId, 10) : undefined;
  }

  _continueToCheckout() {
    const buttonRoot = this.rootContainer.querySelector('shop-pay-button')!;
    const shopPayButton = buttonRoot.shadowRoot;
    const buttonBase = shopPayButton?.querySelector('shop-pay-button-base');

    const buttonLink = buttonBase?.shadowRoot?.querySelector(
      '#shop-pay-button-link',
    )!;
    // Dispatch 2 events because the structure of the button
    // changes depending on where it is being rendered (cart versus product page)
    buttonLink?.dispatchEvent(new MouseEvent('click'));
    buttonBase?.dispatchEvent(new Event('click'));

    this._monorailTracker.trackInstallmentsPrequalPopupPageImpression(
      this._getSellerIdInNumber(),
      InstallmentsPrequalPageType.ContinueToCheckoutClicked,
    );
  }

  #openHiddenLoginIframe() {
    const buttonText = this.getButtonText(this._variantInfo);

    this._navigationButtons.innerHTML = getNavigationButtons(
      '',
      this._modalToken,
      buttonText,
      this._variantInfo,
      this._cart,
    );
    // find the shop-pay-button element created
    const checkIfYouQualifyButton =
      this._navigationButtons.querySelector('shop-pay-button')!;
    const qualifyShadowRoot = checkIfYouQualifyButton.shadowRoot;
    const buttonLink = qualifyShadowRoot?.querySelector(
      '#shop-pay-button-link',
    );
    // remove default functionality of redirecting to the checkout
    buttonLink?.setAttribute('href', '#');
    // add functionality to open the authorize modal
    const shopLoginButton = document.createElement(
      'shop-login-button',
    ) as ShopLoginButton;
    shopLoginButton.setAttribute('action', ShopActionType.Prequal);
    shopLoginButton.setAttribute('client-id', '');
    shopLoginButton.setAttribute('version', '2');
    shopLoginButton.setAttribute(
      'analytics-context',
      DefaultComponentAnalyticsContext.Prequal,
    );
    shopLoginButton.setAttribute('analytics-trace-id', this._analyticsTraceId);
    shopLoginButton.setAttribute('hide-button', 'true');
    shopLoginButton.classList.add('prequal');
    shopLoginButton.setAttribute('anchor-to', 'shop-pay-button');
    document.body.appendChild(shopLoginButton);
    checkIfYouQualifyButton.addEventListener('click', () => {
      switch (this._nextState) {
        case InstallmentsPrequalPageType.AuthorizeLoaded:
          // The following is added to debug an error where shopLoginButton?.requestShow is undefined
          // We are adding this portion as a breadcrumb for our debugging.
          // eslint-disable-next-line no-case-declarations
          const shopLoginButton = document.querySelector(
            '.prequal',
          ) as ShopLoginButton;
          if (!shopLoginButton?.requestShow) {
            Bugsnag.notify(
              new Error(
                `checkIfYouQualifyButton clicked: shopLoginButton.requestShow is not defined`,
              ),
            );
          }
          this._openAuthorizeModal();
          break;
        case InstallmentsPrequalPageType.BuyerFormOverlayLoaded:
          this._openPrequalBuyerFormOverlay();
          break;
        case InstallmentsPrequalPageType.ResultsPageLoaded:
          this._openFeatureIframe();
          break;
      }
    });

    shopLoginButton.addEventListener(
      'prequal_flow_side_effect',
      this.handlePrequalFlowSideEffect,
    );

    shopLoginButton.addEventListener('completed', this.handleLoginCompleted);

    this._closeButtons = this.rootContainer.querySelectorAll('.btn__close');
    if (!this._closeButtons || this._closeButtons.length === 0) return;

    this._closeButtons.forEach((btn) =>
      btn.addEventListener('click', this.handleClose),
    );
    // Autofocus on the first element inside modal
    (this._closeButtons[0] as HTMLButtonElement).focus();
  }

  _openPrequalBuyerFormOverlay() {
    if (document.querySelector('.prequal-buyer-form-overlay')) return;
    const prequalBuyerFormOverlay = document.createElement(
      'installments-prequal-overlay-modal',
    ) as InstallmentsPrequalOverlayModal;
    prequalBuyerFormOverlay.classList.add('prequal-buyer-form-overlay');
    document.body.appendChild(prequalBuyerFormOverlay);

    this._monorailTracker.trackInstallmentsPrequalPopupPageImpression(
      this._getSellerIdInNumber(),
      InstallmentsPrequalPageType.BuyerFormOverlayLoaded,
    );
  }

  _closePrequalBuyerFormOverlay() {
    const prequalBuyerFormOverlay = document.querySelector(
      '.prequal-buyer-form-overlay',
    );
    if (!prequalBuyerFormOverlay) return;
    document.body.removeChild(prequalBuyerFormOverlay);
  }

  _openAuthorizeModal() {
    const shopLoginButton = document.querySelector(
      '.prequal',
    ) as ShopLoginButton;
    const email = '';
    if (!shopLoginButton?.requestShow) {
      Bugsnag.notify(
        new Error(
          `shopLoginButton.requestShow is not defined: €{shopLoginButton}`,
        ),
      );
    }
    shopLoginButton?.requestShow(email);
    this._authorizeModalOpened = true;

    this._monorailTracker.trackInstallmentsPrequalPopupPageImpression(
      this._getSellerIdInNumber(),
      InstallmentsPrequalPageType.AuthorizeLoaded,
    );
  }

  _closeAuthorizeModal() {
    this._authorizeModalOpened = false;
  }

  _showLoadingState() {
    this.#changeHeight('400px');

    this._contentProcessingWrapper.classList.remove('prequal-hidden-state');
    this._featureIframeWrapper.classList.add('prequal-hidden-state');
    this._contentModalWrapper.classList.add('opaque-hidden');

    this._monorailTracker.trackInstallmentsPrequalPopupPageImpression(
      this._getSellerIdInNumber(),
      InstallmentsPrequalPageType.ResultsPageLoading,
    );
  }

  _showFeatureIframe() {
    this.#changeHeight('');
    this._contentModalWrapper.classList.add('prequal-hidden-state');
    this._contentProcessingWrapper.classList.add('prequal-hidden-state');
    this._featureIframeWrapper.classList.remove('prequal-hidden-state');

    this._continueToCheckoutContainer.innerHTML =
      getContinueToCheckoutButtonHtml(
        window.location.origin,
        this._modalToken,
        this._variantInfo,
        this._cart,
      );
  }

  _showMainContent() {
    this._contentModalWrapper.classList.remove('opaque-hidden');
    this._contentProcessingWrapper.classList.add('prequal-hidden-state');
    this._featureIframeWrapper.classList.add('prequal-hidden-state');
  }

  /**
   * @param {VariantModalDetails} variantInfo information on the variant
   * @returns {string} Text for the button
   */
  getButtonText(variantInfo?: VariantModalDetails) {
    if (!this._i18n) return '';
    return variantInfo?.available === false
      ? this._i18n.translate('modal.prequal_contents.unavailable')
      : this._i18n.translate('modal.prequal_contents.check');
  }

  private async _initTranslations(): Promise<void> {
    if (this._i18n) return;
    try {
      const locale = I18n.getDefaultLanguage();
      const dictionary = await import(`./translations/€{locale}.json`);
      this._i18n = new I18n({[locale]: dictionary});
    } catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
      }
    }
  }

  private async _initContent(): Promise<void> {
    if (!this._i18n) return;

    const template = document.createElement('template');

    this._samplePlans = getFormattedSamplePlans(
      this._installmentsPlans,
      this._priceWithoutInterest,
    );

    const {subTitle, legalCopy} = getDynamicPDPText(
      this._i18n,
      this._samplePlans.length,
      this._priceWithoutInterest,
    );

    template.innerHTML = getDynamicPDPTemplateContents(
      this._i18n,
      subTitle,
      legalCopy,
      this._samplePlans,
    );

    this.rootContainer.innerHTML = '';
    this.rootContainer.appendChild(template.content.cloneNode(true));

    this._contentProcessingWrapper = this.rootContainer.querySelector(
      `.shop-modal-content-processing`,
    )!;
    this._contentModalWrapper =
      this.rootContainer.querySelector(`.modal-wrapper`)!;
    this._featureIframeWrapper = this.rootContainer.querySelector(
      `.shop-modal-feature-iframe-wrapper`,
    )!;

    this._navigationButtons = this.rootContainer.querySelectorAll(
      '.navigation-buttons',
    )[0];

    this._continueToCheckoutContainer = this.rootContainer.querySelectorAll(
      '.continue-to-checkout-button',
    )[0];

    // If no cart token is defined, it means that the modal was built for PDP
    this._permalink = this._cart
      ? this._cart.token
      : constructLink({
          storeUrl: window.location.origin,
          variants: this._variantInfo
            ? getVariantsFromAttributeString(
                this._variantInfo.idQuantityMapping,
              )
            : [],
          paymentOption: PaymentOption.ShopPayInstallments,
          source: 'installments_modal',
          sourceToken: this._modalToken,
        });
  }
}
