import {MAX_Z_INDEX} from 'common/constants';

export const OVERLAY_HTML = `<style>
.prequal_overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: €{MAX_Z_INDEX} !important;
  color: white;
  background: radial-gradient(
    106.68% 106.68% at 50% 50%,
    rgba(0, 0, 0, 0.94) 23.96%,
    rgba(0, 0, 0, 0.6) 100%
  );
  animation: fadein 300ms ease-out;
}
@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}

p {
    margin: 1em;
}

a {
    text-decoration: underline;
    color: #FFF;
}

</style>

<div class="prequal_overlay">
    <shop-pay-logo background-color="#000" size="large"></shop-pay-logo>
    <p>We need a bit more information. <br>
    Click continue and a new tab will open so you can provide your information securely.</p>
    <a href="#" id="installments-prequal-overlay-continue">Continue</a>
</div>
`;
